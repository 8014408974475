<template>
  <div style="background-color: var(--gray-white)" class="body1">
    <div class="header-bets">
      <img
        style="
          object-fit: contain;
          border-radius: 7px;
          width: 98%;
          margin-left: 1%;
          margin-right: 1%;
          margin-top: 5px;
        "
        class="bets-img"
        src="/img/carousel/sliderone.jpeg"
      />
    </div>
    <div>
      <div class="bd-callout bd-callout-default list">
        <div
          id="application"
          class="header-hero bg_cover d-lg-flex align-items-center"
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="header-hero-content">
                  <br />
                  <ul style="list-style: none">
                    <li class="mb-2">
                      <router-link
                        style="
                          text-decoration: underline;
                          color: var(--darkBackground);
                        "
                        to="/casino"
                      >
                        <img
                          style="width: 15px; margin-bottom: 4px"
                          src="/assets/images/star.png"
                        />
                        Casino
                      </router-link>
                    </li>
                    <li class="mb-2">
                      <router-link
                        style="
                          text-decoration: underline;
                          color: var(--darkBackground);
                        "
                        to="/live"
                      >
                        <img
                          style="width: 15px; margin-bottom: 4px"
                          src="/assets/images/star.png"
                        />
                        Live Games
                      </router-link>
                    </li>
                    <li class="mb-2">
                      <router-link
                        style="
                          text-decoration: underline;
                          color: var(--darkBackground);
                        "
                        to="/jackpot-games"
                      >
                        <img
                          style="width: 15px; margin-bottom: 4px"
                          src="/assets/images/star.png"
                        />
                        Jackpots
                      </router-link>
                    </li>
                    <li class="mb-2">
                      <router-link
                        style="
                          text-decoration: underline;
                          color: var(--darkBackground);
                        "
                        to="/"
                      >
                        <img
                          style="width: 15px; margin-bottom: 4px"
                          src="/assets/images/star.png"
                        />
                        Crash games
                      </router-link>
                    </li>
                    <li class="mb-2">
                      <!-- <a href="/app/chopLife-app-v1.0.1.apk#howto" class="btn btn-sm"
                                                style="scroll-behavior: smooth;background-color: var(--darkBackground);color: var(--white);" @click.prevent="scrollToHowTo">
                                                <img style="width: 15px;margin-bottom: 4px;"
                                                    src="/assets/images/play.png">
                                                Download APK
                                            </a> -->
                      <a
                        href="#"
                        class="btn btn-sm"
                        style="
                          scroll-behavior: smooth;
                          background-color: var(--darkBackground);
                          color: var(--white);
                        "
                        @click.prevent="handleDownloadAndScroll"
                      >
                        <img
                          style="width: 15px; margin-bottom: 4px"
                          src="/assets/images/play.png"
                        />
                        Download APK
                      </a>
                    </li>
                  </ul>
                </div>
                <!-- header hero content -->
              </div>

              <!-- <div class="col-12">
                                <img src="/assets/images/first_mock.png" alt="app" style="width: 37%;"
                                    data-wow-delay="0.5s">
                                <img src="/assets/images/second_mock.png" alt="app" style="width: 63%;"
                                    data-wow-delay="0.5s">
                            </div> -->
              <!-- <div class="col-12">
                                <span id="howto"></span>
                                <br />
                                <br />
                                <h5 style="color: var(--darkBackground);">HOW TO INSTALL THE ANDROID APP</h5>
                                <div class="step-card" v-show="currentStep === 0">
                                    <h3 style="color: var(--darkBackground);">Step 1</h3>
                                    <p style="color: var(--darkBackground);">Download</p>
                                    <div style="text-align: center;">
                                        <img src="/assets/images/1.webp" alt="app"
                                            style="width: 150px; display: inline-block;" data-wow-delay="0.5s">
                                        <p style="color: var(--darkBackground);">Click "Download" to begin</p>
                                    </div>
                                    <button
                                        style="float: right;background-color: var(--darkBackground);color: var(--white);"
                                        class="btn btn-sm btn-warning" @click="nextStep">Next</button>
                                    <br />
                                </div>

                                <div class="step-card" v-show="currentStep === 1">
                                    <h3 style="color: var(--darkBackground);">Step 2</h3>
                                    <p style="color: var(--darkBackground);">Unknown app</p>
                                    <div style="text-align: center;">
                                        <img src="/assets/images/2.webp" alt="app"
                                            style="width: 150px; display: inline-block;" data-wow-delay="0.5s">
                                        <p style="color: var(--darkBackground);">Open the "Install unknown apps" section
                                            in Settings.</p>
                                    </div>
                                    <button
                                        style="float: left;background-color: var(--darkBackground);color: var(--white);"
                                        class="btn btn-sm btn-warning" @click="prevStep">Previous</button>
                                    <button
                                        style="float: right;background-color: var(--darkBackground);color: var(--white);"
                                        class="btn btn-sm btn-warning" @click="nextStep">Next</button>
                                    <br />
                                </div>
                                <div class="step-card" v-show="currentStep === 2">
                                    <h3 style="color: var(--darkBackground);">Step 3</h3>
                                    <p style="color: var(--darkBackground);">Allow installation of unknown app</p>
                                    <div style="text-align: center;">
                                        <img src="/assets/images/3.webp" alt="app"
                                            style="width: 150px; display: inline-block;" data-wow-delay="0.5s">
                                        <p style="color: var(--darkBackground);">Allow app installation from unknown
                                            sources.</p>
                                    </div>
                                    <button
                                        style="float: left;background-color: var(--darkBackground);color: var(--white);"
                                        class="btn btn-sm btn-warning" @click="prevStep">Previous</button>
                                    <button
                                        style="float: right;background-color: var(--darkBackground);color: var(--white);"
                                        class="btn btn-sm btn-warning" @click="nextStep">Next</button>
                                    <br />
                                </div>
                                <div class="step-card" v-show="currentStep === 3">
                                    <h3 style="color: var(--darkBackground);">Step 4</h3>
                                    <p style="color: var(--darkBackground);">Select app</p>
                                    <div style="text-align: center;">
                                        <img src="/assets/images/4.webp" alt="app"
                                            style="width: 150px; display: inline-block;" data-wow-delay="0.5s">
                                        <p style="color: var(--darkBackground);">Select the downloaded app file.</p>
                                    </div>
                                    <button
                                        style="float: left;background-color: var(--darkBackground);color: var(--white);"
                                        class="btn btn-sm btn-warning" @click="prevStep">Previous</button>
                                    <button
                                        style="float: right;background-color: var(--darkBackground);color: var(--white);"
                                        class="btn btn-sm btn-warning" @click="nextStep">Next</button>
                                    <br />
                                </div>
                                <div class="step-card" v-show="currentStep === 4">
                                    <h3 style="color: var(--darkBackground);">Step 5</h3>
                                    <p style="color: var(--darkBackground);">Install</p>
                                    <div style="text-align: center;">
                                        <img src="/assets/images/5.webp" alt="app"
                                            style="width: 150px; display: inline-block;" data-wow-delay="0.5s">
                                        <p style="color: var(--darkBackground);">Tap "Install".</p>
                                    </div>
                                    <button
                                        style="float: left;background-color: var(--darkBackground);color: var(--white);"
                                        class="btn btn-sm btn-warning" @click="prevStep">Previous</button>
                                    <button
                                        style="float: right;background-color: var(--darkBackground);color: var(--white);"
                                        class="btn btn-sm btn-warning" @click="nextStep">Next</button>
                                    <br />
                                </div>
                                <div class="step-card" v-show="currentStep === 5">
                                    <h3 style="color: var(--darkBackground);">Step 6</h3>
                                    <p style="color: var(--darkBackground);">Installed</p>
                                    <div style="text-align: center;">
                                        <img src="/assets/images/6.webp" alt="app"
                                            style="width: 150px; display: inline-block;" data-wow-delay="0.5s">
                                        <p style="color: var(--darkBackground);">App installed.</p>
                                    </div>
                                    <button
                                        style="float: left;background-color: var(--darkBackground);color: var(--white);"
                                        class="btn btn-sm btn-warning" @click="prevStep">Previous</button>
                                    <br />
                                </div>
                            </div> -->
            </div>
            <!-- row -->
          </div>
          <!-- container -->
        </div>
      </div>
    </div>
    <bottom-navigation active-bets="0" page="login"></bottom-navigation>
  </div>
</template>

<script>
import BottomNavigation from "@/components/BottomNavigation";

export default {
  name: "Mobile",
  components: {
    BottomNavigation,
  },
  data() {
    return {
      currentStep: 0,
      showModal: false,
    };
  },
  methods: {
    handleDownloadAndScroll() {
      setTimeout(() => {
        window.location.href = "/app/chopLife-app-v1.0.1.apk";
      }, 500);
    },
    scrollToHowTo() {
      document.getElementById("howto").scrollIntoView({ behavior: "smooth" });
    },
    nextStep() {
      if (this.currentStep < 6) {
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    },
  },
  mounted() {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the default prompt
      event.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
    });
  },
};
</script>

<style scoped>
.multi-step-card {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  height: 100%;
}

.step-card {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: opacity 0.3s ease;
}
</style>
